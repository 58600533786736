import { computed } from '@vue/composition-api'

const USER_GUIDING_FLAG_KEY = 'userGuidingFlag'
const ALWAYS_ASSIST_KEY = 'alwaysAssist'

const GUIDE_IDS = {
  ASSIST_NEW_TABLE: 122244,
  ASSIST_SHOW_TABLE: 126733,
}

export default () => {
  const userGuidingFlag = computed(() => {
    return JSON.parse(localStorage.getItem(USER_GUIDING_FLAG_KEY)) || {}
  })

  const isAlwaysAssistOn = computed(() => {
    return userGuidingFlag.value[ALWAYS_ASSIST_KEY]
  })

  const userIdentify = (userId, attributes = {}) => {
    window.userGuiding.identify(userId, attributes)
  }

  const triggerGuide = guideName => {
    const guideId = GUIDE_IDS[guideName]

    if (guideId) {
      window.userGuiding.previewGuide(guideId)
    } else {
      console.warn(`Invalid guide name: ${guideName}`)
    }
  }

  return {
    userGuidingFlag,
    isAlwaysAssistOn,
    userIdentify,
    triggerGuide,
  }
}
